import {useState} from 'react'
import './App.css'
import {
  CssBaseline,
  Container,
  Box,
  Paper,
  Typography,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material"
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { BarChart } from '@mui/x-charts/BarChart';

import stats from './file.json'

window.s = stats
var nrGames = 2
var fighters = getAllFighters(stats)
var players = new Set(fighters.map(f => f.name))
var points = fighters.map(p => p.pointSum)

var numPlayers = players.size

var pointFrequencies = points.reduce((acc, e) => acc.set(e, (acc.get(e) || 0) + 1), new Map());

var categories = Object.keys(stats)

const darkTheme = createTheme({
  palette: {
  primary: {
    mode: 'dark',
      main: '#3b512a'
    },
    secondary: {
      main: '#8278d9'
    }
  },
  typography: {
    h2: {
      color: '#5F7470',
      fontFamily: 'Roboto',
      fontWeight: '400',
    },
    h3: {
      color: '#242f40',
      fontFamily: 'Roboto serif',
      fontWeight: '400',
    },
    h4: {
      color: '#141204',
      fontWeight: '300',
      fontFamily: 'Roboto',
    },
    h5: {
      fontFamily: 'Roboto serif',
      opacity: '0.6'
    },
    subtitle: {
      fontFamily: 'Roboto',
      color: '#5F7470',
      opacity: '0.8',
    }
  }
})



function App() {
  var [categorySelected, setCategory] = useState('all')

  const handleChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value)
  }


  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline/>
      <Container maxWidth="md">
        <Box sx={{ height: '100vh' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center'}}>
            <Typography variant="h3" sx={{ mt: '.5em', mb: '.5em'}}>
                — Taekwon-<i>whomst</i> —
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', gap: '1em'}}>
            <Box sx={{ display: 'flex', gap: '1em', flexBasis: '50%', flexWrap: 'wrap'}}>
              <Box sx={{ display: 'flex', gap: '1em', flexGrow: 1}}>
                <Paper sx={{
                  backgroundColor:'#f2f2f2',
                  aspectRatio: '1 / 1',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 'calc(50% - .5em)',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                  padding: '1em'
                }}>
                  <Typography variant='h2' sx={{ fontSize: '6em'}}>{numPlayers}</Typography>
                  <Typography variant='h4'>Fighters</Typography>
                </Paper>
                <Paper sx={{
                  backgroundColor:'#f2f2f2',
                  aspectRatio: '1 / 1',
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  minWidth: 'calc(50% - .5em)',
                  flexDirection: 'column',
                  padding: '1em'}}>
                  <Typography variant='h2' sx={{ fontSize: '6em'}}>{nrGames}</Typography>
                  <Typography variant='h4'>Events</Typography>
                </Paper>
              </Box>
              <Paper sx={{
                backgroundColor:'#f2f2f2',
                flexBasis: '100%',
                padding: '1em',
                height: '6em'
                }}>
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Category</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={categorySelected}
                    onChange={handleChange}
                    color='primary'
                  >
                  <MenuItem value={'all'}>All</MenuItem>
                  {categories.map(c => {return (
                    <MenuItem key={c} value={c}>{c}</MenuItem>
                    )})}
                  </Select>
                  <FormHelperText>Ranking per Category</FormHelperText>
                </FormControl>
              </Paper>
            </Box>
            <Paper sx={{
              backgroundColor:'#f2f2f2',
              minHeight: '10em',
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
              padding: '1em',
              flexDirection: 'column',
              minWidth: 'calc(50%)'}}>
                 <PointDistribution points={pointFrequencies}></PointDistribution>
                 <Typography variant='h4'>Point Distribution</Typography>
            </Paper>
          </Box>
          <Fighters selected={categorySelected}></Fighters>
        </Box>
      </Container>  
    </ThemeProvider>
  );
}
export default App;

function PointDistribution (props) {
  var pointFrequencies = props.points

  var keysorted = [...pointFrequencies.keys()].sort((a,b) => a - b)
  var valssorted = keysorted.map(k => pointFrequencies.get(k))
  return (
                <BarChart
                  xAxis={[{ scaleType: 'band', data: keysorted, categoryGapRatio: 0.5, barGapRatio: 0.1 }]}
                  series={[{ data: valssorted, color: '#5F7470' }]}
                  height={230}/>
  )
}

function Fighters (props) {
  var categorySelected = props.selected
  var targetCategory = stats[categorySelected]

  if (categorySelected !== 'all') {
    var fighters = Object.keys(targetCategory).map(fighter => {
      return { name: fighter, ...targetCategory[fighter]}
    }).sort((a,b) => a.pointSum < b.pointSum)
  } else {
    fighters = getAllFighters(stats).sort((a, b) => a.pointSum < b.pointSum)
  }

  return fighters.map(fighter => {
    return (
      <Paper sx={{
        backgroundColor:'#f2f2f2',
        display: 'flex',
        marginTop: '1em',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '1em',
        minWidth: 'calc(50%)'}}>
        <Box sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}>
          <Box className="fighterBox">
            <Typography variant='h4'>{fighter.name}</Typography>
            <Typography variant='subtitle' sx={{ textTransform: 'uppercase'}}>
              {fighter.school}
            </Typography>
          </Box>
          <Box>
            <Typography variant='h2'>{fighter.pointSum}p</Typography>
          </Box>
        </Box>
      </Paper>
    )
  })
}

function getAllFighters (stats) {
  var fighters = []
  var cats = Object.keys(stats)
  cats.forEach(c => {
    var cat = stats[c]
    Object.keys(cat).forEach(f => {
      fighters.push({name: f, ...cat[f]})
    })
  })
  return fighters
}

/* green #58A352
 * jet #363636
 * gunmetal #242f40
  * platinum #f2f2f2
 * */
